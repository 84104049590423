import React from "react";

export default function useWindowSize() {
    const [windowSize, setWindowSize] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    function changeWindowSize() {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }

    React.useEffect(() => {
        window.onload(changeWindowSize);
    }, []);

    return windowSize;
}
