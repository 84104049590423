import React from "react";
import {  Space } from "antd";
import { Link } from "react-router-dom";
import { ContainerOutlined, DownloadOutlined, HomeOutlined, UploadOutlined, UserOutlined } from "@ant-design/icons";

const Bottomnav = () => {
    return (
        <footer>
            <div className="nav">
                <div className="nav-button">
                    <Link to="/" className="nav-child">
                        <Space direction="vertical">
                            <HomeOutlined style={{ fontSize: "20px" }} />
                        </Space>
                    </Link>
                </div>
                <div className="nav-button">
                    <Link to="/surat_masuk" className="nav-child">
                        <Space direction="vertical">
                            <DownloadOutlined style={{ fontSize: "20px" }} />
                        </Space>
                    </Link>
                </div>
                <div className="nav-button">
                    <Link to="/surat_keluar" className="nav-child">
                        <Space direction="vertical">
                            <UploadOutlined style={{ fontSize: "20px" }} />
                        </Space>
                    </Link>
                </div>
                <div className="nav-button">
                    <Link to="/jenis" className="nav-child">
                        <Space direction="vertical">
                            <ContainerOutlined style={{ fontSize: "20px" }} />
                        </Space>
                    </Link>
                </div>
                <div className="nav-button">
                    <Link to="/pengguna" className="nav-child">
                        <Space direction="vertical">
                            <UserOutlined style={{ fontSize: "20px" }} />
                        </Space>
                    </Link>
                </div>
            </div>
        </footer>
    );
};

export default Bottomnav;
