import React, { useContext, useState } from "react";
import { AuthContext } from "../pages/login/context/LoginContext";
import { Layout, Menu, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import {
  HomeOutlined,
  LogoutOutlined,
  MailOutlined,
  SettingOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const { Sider } = Layout;
const { SubMenu } = Menu;

const rootSubmenuKeys = ["sub1", "sub2"];
const Side = () => {
  const [openKeys, setOpenKeys] = useState([]);
  const [, setUser] = useContext(AuthContext);
  const location = useLocation();

  const handleLogout = () => {
    setUser(null);
    localStorage.clear();
  };
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Sider>
      <img src="new_ubj.webp" className="logo" alt="logo UBJ" />
      <Menu
        theme="dark"
        selectedKeys={[location.pathname]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        mode="inline"
      >
        <Menu.Item key="/" icon={<HomeOutlined />}>
          <Link to="/">Beranda</Link>
        </Menu.Item>
        <SubMenu key="sub1" icon={<MailOutlined />} title="Surat">
          <Menu.Item key="/surat_masuk">
            <Link to="/surat_masuk">Surat Masuk</Link>
          </Menu.Item>
          <Menu.Item key="/surat_keluar">
            <Link to="/surat_keluar">Surat Keluar</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="/berkas" icon={<FileOutlined />}>
          <Link to="/berkas">Berkas</Link>
        </Menu.Item>
        <SubMenu key="sub2" icon={<SettingOutlined />} title="Pengaturan">
          <SubMenu key="sub3" icon={<MailOutlined />} title="Surat">
            <Menu.Item key="/jenis">
              <Link to="/jenis">Jenis Surat</Link>
            </Menu.Item>
            <Menu.Item key="/pengguna">
              <Link to="/pengguna">Pengguna</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub4" icon={<FileOutlined />} title="Berkas">
            <Menu.Item key="/berkas/lokasi/gedung">
              <Link to="/berkas/lokasi/gedung">Gedung</Link>
            </Menu.Item>
            <Menu.Item key="/berkas/lokasi/ruang">
              <Link to="/berkas/lokasi/ruang">Ruang</Link>
            </Menu.Item>
            <Menu.Item key="/berkas/lokasi/lemari">
              <Link to="/berkas/lokasi/lemari">Lemari</Link>
            </Menu.Item>
          </SubMenu>
        </SubMenu>
        <Menu.Item key="7" icon={<LogoutOutlined />}>
          <Popconfirm
            placement="right"
            title={"Anda akan keluar?"}
            onConfirm={handleLogout}
            okText="Ya"
            cancelText="Tidak"
          >
            <Link to="/login">Keluar</Link>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};
export default Side;
