import React, { Component } from "react";
import "./App.css";
import "antd/dist/antd.css";
import { BrowserRouter as Router } from "react-router-dom";
import Dashboard from "./components/layouts/Dashboard";
import { LoginProvider } from "./components/pages/login/context/LoginContext";

class App extends Component {
    componentDidMount() {
        const elem = document.getElementById("startingLoader");
        window.onload = () => {
            if (elem) {
                elem.remove();
            }
        };
    }
    render() {
        return (
            <Router>
                <LoginProvider>
                    <Dashboard />
                </LoginProvider>
            </Router>
        );
    }
}

export default App;
