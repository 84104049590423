import React, { Suspense, lazy, useContext } from "react";
import { Spin } from "antd";
import { Redirect, Route, Switch } from "react-router-dom";
import { AuthContext } from "../pages/login/context/LoginContext";
const Dashboard = lazy(() => import("../pages/home/Home"));
const Users = lazy(() => import("../pages/pengguna/container"));
const Login = lazy(() => import("../pages/login/container"));
const Jns = lazy(() => import("../pages/jenis/container"));
const Suratmasuk = lazy(() => import("../pages/suratMasuk/container"));
const Suratkeluar = lazy(() => import("../pages/suratKeluar/container"));
const Berkas = lazy(() => import("../pages/berkas/container"));
const NotFound = lazy(() => import("../pages/404/404"));

const Routes = () => {
  const [user] = useContext(AuthContext);

  const PrivateRoute = ({ user, ...props }) => {
    if (user) {
      return <Route {...props} />;
    } else {
      return <Redirect to="/login" />;
    }
  };

  const LoginRoute = ({ user, ...props }) =>
    user ? <Redirect to="/" /> : <Route {...props} />;
  return (
    <Suspense
      fallback={
        <div className="loading">
          <Spin tip="Sedang memuat..." size="large"></Spin>
        </div>
      }
    >
      <Switch>
        <PrivateRoute path="/" exact user={user} component={Dashboard} />
        <LoginRoute path="/login" user={user} component={Login} />
        <PrivateRoute path="/pengguna" user={user} component={Users} />
        <PrivateRoute path="/jenis" user={user} component={Jns} />
        <PrivateRoute path="/surat_masuk" user={user} component={Suratmasuk} />
        <PrivateRoute
          path="/surat_keluar"
          user={user}
          component={Suratkeluar}
        />
        <PrivateRoute path="/berkas" user={user} component={Berkas} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
