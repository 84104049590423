import React, { useContext, useEffect } from "react";
import { Layout, Menu, Popconfirm } from "antd";
import Side from "./Side";
import Routes from "../router/Routes";
import { AuthContext } from "../pages/login/context/LoginContext";
import Bottomnav from "./Bottomnav";
import useWindowSize from "../../utils/useWindowSize";
import { UserOutlined } from "@ant-design/icons";

const { Header, Content } = Layout;
const { SubMenu } = Menu;

const Dashboard = () => {
    const { width } = useWindowSize();
    const [user,setUser] = useContext(AuthContext);
    useEffect(() => {
        if(user){
            if (Date.now() > user.exp * 1000) {
                setUser(null);
                localStorage.clear();
            }
        }
    });
    
    const handleClick = () => {
            setUser(null);
            localStorage.clear();
    };
    return (
        <>
            <Layout style={{ minHeight: "100vh" }}>
                {user ? (
                    <>
                        {width > 662 && <Side />}
                        <Layout>
                            <Header className="site-layout-sub-header-background">
                                {width <= 662 ? (
                                    <Menu mode="horizontal" theme="dark">
                                        <SubMenu
                                            icon={<UserOutlined />}
                                            title={`Hai, ${user.username}`}
                                            style={{
                                                float: "right",
                                            }}
                                        >
                                            <Menu.Item key="1">
                                                <Popconfirm
                                                    placement="bottomRight"
                                                    title={"Anda akan keluar?"}
                                                    onConfirm={handleClick}
                                                    okText="Ya"
                                                    cancelText="Tidak"
                                                >
                                                    Keluar
                                                </Popconfirm>
                                            </Menu.Item>
                                        </SubMenu>
                                    </Menu>
                                ) : (
                                    <div
                                        style={{
                                            float: "right",
                                            color: "#ffffff",
                                        }}
                                    >
                                        <UserOutlined /> Hai, {user.username}
                                    </div>
                                )}
                            </Header>
                            <Content style={{ margin: 8 }}>
                                <div
                                    className="site-layout-background"
                                    style={{ padding: 10, minHeight: 360, borderRadius: 10 }}
                                >
                                    <Routes />
                                </div>
                            </Content>
                        </Layout>
                    </>
                ) : (
                    <Routes />
                )}
            </Layout>

            {user && width <= 662 && <Bottomnav />}
        </>
    );
};
export default Dashboard;

